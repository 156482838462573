<template>
  <div v-if="!angemeldeterMitarbeiter" class="container-fluid tile-container">
    <div class="row mt-70-px">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="row">
          <div class="col-xl-12 col-lg-6 col-md-6">
            <div class="tile-categorie-frame">
              <div class="tile-categorie">
                <div class="header text-center mt-4">
                  <span>Passwort zurücksetzen</span>
                </div>
                <div class="m-4">
                  <div class="mb-3" v-if="resetSuccessful">
                    <span class="mr-2">
                      <font-awesome-icon icon="fa-regular fa-shield-check" />
                    </span>
                    Passwort erfolgreich zurückgesetzt!
                  </div>
                  <div class="col-12 reset-pw-input" v-if="!resetSuccessful">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <font-awesome-icon
                            icon="fa-duotone fa-key"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <input
                        :type="showpassword ? 'text' : 'password'"
                        name="password"
                        class="form-control"
                        placeholder="Neues Passwort"
                        aria-describedby="Passwort"
                        autocapitalize="off"
                        spellcheck="false"
                        autocorrect="off"
                        v-model="password"
                      />
                      <div class="input-group-append" style="position: initial">
                        <span
                          class="input-group-text"
                          style="cursor: pointer"
                          @click="toggleShowPassword"
                        >
                          <font-awesome-icon
                            class="fa-fw"
                            v-if="!showpassword"
                            icon="fa-regular fa-eye"
                          />
                          <font-awesome-icon
                            class="fa-fw"
                            v-if="showpassword"
                            icon="fa-regular fa-eye-slash"
                          />
                        </span>
                      </div>
                    </div>

                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <font-awesome-icon
                            icon="fa-duotone fa-key"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <input
                        :type="showpassword ? 'text' : 'password'"
                        name="repeatPassword"
                        class="form-control"
                        placeholder="Neues Passwort wiederholen"
                        aria-describedby="Neues Passwort wiederholen"
                        autocapitalize="off"
                        spellcheck="false"
                        autocorrect="off"
                        v-model="repeatPassword"
                      />
                      <div class="input-group-append" style="position: initial">
                        <span
                          class="input-group-text"
                          style="cursor: pointer"
                          @click="toggleShowPassword"
                        >
                          <font-awesome-icon
                            class="fa-fw"
                            v-if="!showpassword"
                            icon="fa-regular fa-eye"
                          />
                          <font-awesome-icon
                            class="fa-fw"
                            v-if="showpassword"
                            icon="fa-regular fa-eye-slash"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <span v-if="!passwordFormatIsValid"
                        >Das Passwort muss Mindestens 8-stellig sein, Gross- und
                        Kleinbuchstaben, eine Zahl und ein Sonderzeichen
                        beinhalten.</span
                      >
                    </div>
                    <div class="col-12">
                      <span v-if="!passwordsMatch"
                        >Die eingegebenen Passwörter stimmen nicht überein</span
                      >
                    </div>
                  </div>
                  <div v-if="!resetSuccessful">
                    <button
                      class="btn btn-primary w-100 mb-3"
                      @click="resetPassword"
                      :disabled="!password || !repeatPassword"
                    >
                      Bestätigen
                    </button>
                  </div>
                  <div
                    class="hover-medium-blue d-inline-block"
                    style="cursor: pointer"
                    v-if="resetSuccessful"
                    @click="goToLogin"
                  >
                    Zurück zum Login
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</template>

<script>
//import Api from "@/Api";
import axios from "axios";
import server from "../server";

export default {
  name: "NewPassword",
  data() {
    return {
      password: "",
      repeatPassword: "",
      showpassword: false,
      resetSuccessful: false,
      token: null,
    };
  },
  computed: {
    resetPW_URL() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}/password/update/`;
      } else {
        return "/password/update/";
      }
    },

    passwordFormatIsValid() {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[A-Za-z\d\S]{8,}$/;

      if (this.password) return passwordRegex.test(this.password);
      return true;
    },

    passwordsMatch() {
      if (
        this.password &&
        this.repeatPassword &&
        this.password === this.repeatPassword
      )
        return true;
      if (
        (!this.password && !this.repeatPassword) ||
        (this.password && !this.repeatPassword) ||
        (!this.password && this.repeatPassword)
      )
        return true;
      return false;
    },
  },
  created() {
    this.token = this.$route.query.token;
  },
  methods: {
    toggleShowPassword() {
      this.showpassword = !this.showpassword;
    },

    /*     resetPassword() {
      if (this.token && this.password) {
        Api.put(
          `password/reset/?token=${this.token}&password=${this.password}`
        ).then(() => {
          this.resetSuccessful = true;
        });
      }
      this.resetSuccessful = true;
    }, */
    async resetPassword() {
      const params = new URLSearchParams();
      params.append("token", this.token);
      params.append("password", this.password);

      try {
        let response = await axios.post(this.resetPW_URL, params);
        console.log(response);
        if (response.status == 201) {
          this.resetSuccessful = true;
        } else {
          return;
        }
        // let startseite = this.$router.resolve({ name: "ERP Startseite" });
        // window.location.href = startseite.href;
      } catch (error) {
        console.log(error);
      }
    },

    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-signin {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  padding: 15px;
}
</style>
